import React, { FC, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

interface SuccessVideoUploadPopupProps {
  onClose: () => void;
}

const SuccessVideoUploadPopup: FC<SuccessVideoUploadPopupProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const onClickMyVideos = useCallback(() => {
    navigate("/my-videos");
  }, [navigate]);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Success!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your video has been successfully uploaded! It will be available on
          your videos page in a few minutes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="bttn bttn-stroke-yellow" variant="secondary" onClick={onClickMyVideos}>
          My videos
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessVideoUploadPopup;
