import React, { useState, useEffect } from "react";
import { Level } from "../../models/Level";
import { useAuth } from "../../providers/AuthProvider";
import { getLevels } from "../../api/levels";

interface LevelSelectorProps {
  onLevelClick: (level: Level) => void;
  onSubLevelClick: (subLevel: string) => void;
  onSubLevelCloseClick: () => void;
  currentLevel: string;
  currentSubLevel: string;
}

const LevelSelector: React.FC<LevelSelectorProps> = ({
  onLevelClick,
  onSubLevelClick,
  onSubLevelCloseClick,
  currentLevel,
  currentSubLevel,
}) => {
  const [levels, setLevels] = useState<Level[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<Level | null>(null);
  const [selectedSubLevel, setSelectedSubLevel] = useState<string>("all");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { authState } = useAuth();

  // Fetch levels on component mount
  useEffect(() => {
    getLevels(authState.tokens?.access_token ?? "").then((fetchedLevels) => {
      setLevels(fetchedLevels);
    });
  }, [authState.tokens?.access_token]);

  // Update selectedLevel and selectedSubLevel based on currentLevel and currentSubLevel props
  useEffect(() => {
    if (levels.length > 0) {
      const level = levels.find((level) => level.id === currentLevel) || null;
      setSelectedLevel(level);
      setSelectedSubLevel(currentSubLevel);
      if (currentSubLevel !== "all") {
        setIsOpen(!!level); // Open if level is valid
      }
    }
  }, [levels, currentLevel, currentSubLevel]);

  const handleLevelClick = (level: Level) => {
    if (
      selectedLevel != level ||
      (selectedLevel == level && selectedSubLevel !== "all")
    ) {
      setSelectedLevel(level);
      setSelectedSubLevel("all");
      localStorage.setItem("selectedLevel", level.id);
      onLevelClick(level);
    }
    setIsOpen(true); // Open the level selector
  };

  const handleSubLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subLevel = e.target.value;
    setSelectedSubLevel(subLevel);
    onSubLevelClick(subLevel);
  };

  const handleCloseClick = () => {
    setIsOpen(false); // Close the level selector
    if (selectedSubLevel !== "all") {
      onSubLevelCloseClick(); // Trigger close callback
    }
  };

  const handleReopenClick = () => {
    setIsOpen(true); // Re-open the level selector
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  return (
    // <div
    //   className={
    //     window.scrollY > 275
    //       ? "scrolled bg-yellow border-bottom sticky-top-custom"
    //       : "not-scrolled bg-yellow border-bottom sticky-top-custom"
    //   }
    // >
    <div
      className={
        scroll
          ? "scrolled bg-yellow border-bottom sticky-top-custom"
          : "not-scrolled bg-yellow border-bottom sticky-top-custom"
      }
    >
      <div className="d-flex flex-row justify-content-center align-items-center gap-2">
        <button
          className="rounded-pill close-category-levels px-0 bttn bttn-smaller"
          onClick={handleCloseClick}
          style={{ display: isOpen ? "block" : "none" }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.34315 1.34313L12.6569 12.6568M1.34315 12.6568L12.6569 1.34313"
              stroke="white"
              strokeLinecap="round"
            ></path>
          </svg>
        </button>
        <div className="d-flex flex-row flex-md-row flex-wrap">
          {isOpen ? (
            // When the selector is open, show only the selected level and its sub-levels
            selectedLevel && (
              <div className="d-flex flex-row flex-md-row flex-wrap">
                <button className="bttn bttn-smaller me-2">
                  {selectedLevel.name}
                </button>
                <select
                  className="form-select category-levels rounded-pill px-4 pe-5 py-2 rounded-pill w-auto"
                  value={selectedSubLevel}
                  onChange={handleSubLevelChange}
                >
                  <option value="all">All levels</option>
                  {selectedLevel.subLevels.map((subLevel, index) => (
                    <option key={index} value={subLevel}>
                      {subLevel}
                    </option>
                  ))}
                </select>
              </div>
            )
          ) : (
            // When the selector is closed, show all levels
            <>
              {!levels.some((level) => level.id === selectedLevel?.id) && (
                <button
                  className="bttn bttn-smaller me-2"
                  onClick={handleReopenClick}
                >
                  {selectedLevel?.name}
                </button>
              )}
              {levels.map((level) => (
                <div className="d-flex" key={level.id}>
                  <button
                    className={
                      selectedLevel?.id === level.id
                        ? "bttn bttn-smaller me-2"
                        : "bttn bttn-stroke bttn-smaller me-2"
                    }
                    onClick={() => handleLevelClick(level)}
                  >
                    {level.name}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LevelSelector;
