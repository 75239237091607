import React from "react";
import { UserVideo } from "../../models/UserVideo";
import { formatDate } from "../../utility/DateFormatter";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";

interface MyVideoCardProps {
  id: string;
  video: UserVideo;
  isPlaying: boolean;
  isModalView: boolean;
  onPlay: () => void;
  onTryAgainClick: (songId?: string) => void;
  onFindOpponentClick: (userVideo: UserVideo) => void;
  onDelete: (video: UserVideo) => void;
  onVideoSelected: (video: UserVideo) => void;
}

const MyVideoCard: React.FC<MyVideoCardProps> = ({
  id,
  video,
  isPlaying,
  isModalView,
  onPlay,
  onTryAgainClick,
  onFindOpponentClick,
  onDelete,
  onVideoSelected,
}) => {
  const localCreatedDate = formatDate(new Date(video.createdAt));

  const reviewContent =
    video.reviewState === "pending" ? (
      <span>Review in process, we will notify you in 24h.</span>
    ) : (
      <span>Review score: {video.percentage}%</span>
    );

  const actionButton =
    isModalView ? (
      <button
        onClick={() => onVideoSelected(video)}
        className="bttn bttn-stroke align-self-end"
      >
        Select
      </button>
    ) :
      video.percentage < 85 ? (
        <button
          onClick={() => onDelete(video)}
          className="bttn bttn-stroke align-self-end"
        >
          Delete
        </button>
      ) : (
        <button
          onClick={() => onFindOpponentClick(video)}
          className="bttn bttn-stroke align-self-start"
          disabled={video.percentage < 85}
        >
          Go battle
        </button>
      );

  return (

    <div className="card-songs card-songs-listing d-flex flex-column h-100 rounded-5">
      <div
        className="song-image position-relative"
        style={{ position: "relative" }}
      >
        {isPlaying ? (
          <AWSVideoPlayerForm
            videoUrl={video.videoUrl}
            thumbnailUrl={video.thumbnailUrl}
            isPrivate={true}
            autoplay={true}
          />
        ) : (
          <>
            <img
              crossOrigin="anonymous"
              src={video?.thumbnailUrl}
              alt="Thumbnail"
              style={{ width: "100%", height: "auto" }}
            />
            <button
              onClick={onPlay}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60px",
                height: "60px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                border: "none",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                zIndex: 10,
              }}
            >
              <i className="fa fa-play fa-2x" style={{ color: "white" }}></i>
            </button>
          </>
        )}
      </div>
      <div className="song-info px-3 px-md-3 py-3 mt-0">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h3 className="fs-4 m-0 fw-bold">
              {video.song?.artistName || " "}
            </h3>
            <p className="mb-0 fs-6">{video.song?.songTitle || " "}</p>
          </div>
        </div>
      </div>
      <div className="p-3 p-md-3 d-flex justify-content-between align-items-center w-100">
        {reviewContent}
        {actionButton}
      </div>
    </div>

  );
};

export default MyVideoCard;
