import React, { CSSProperties, FC, useMemo } from "react";
import LearnSongsCard, { LearnSongsCardType } from "./LearnSongsCard";
import ReactPaginate from "react-paginate";

export interface SongCardsContainerProps {
  songs: LearnSongsCardType[];
  containerPosition?: CSSProperties["position"];
  containerTop?: CSSProperties["top"];
  containerLeft?: CSSProperties["left"];
  childAction: (song: LearnSongsCardType) => void;
  showLevel?: boolean;
  onPageChange?: (data: { selected: number }) => void;
  pageCount: number; // Total number of pages
  currentPage: number; // Current page (zero-based index)
}

const SongCardsContainer: FC<SongCardsContainerProps> = ({
  songs,
  childAction,
  containerPosition,
  containerTop,
  containerLeft,
  showLevel,
  pageCount,
  currentPage, // Zero-based index for current page
  onPageChange,
}) => {
  const containerStyle: CSSProperties = useMemo(() => {
    return {
      position: containerPosition,
      top: containerTop,
      left: containerLeft,
    };
  }, [containerPosition, containerTop, containerLeft]);

  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected; // Zero-based index
    if (onPageChange) {
      onPageChange({ selected: selectedPage }); // Pass zero-based page to parent
    }
    window.scrollTo(0, 0); // Scroll to top when the page changes
  };;

  return (
    <>
      {songs && (
        <div className="row" style={containerStyle}>
          {songs.map((song) => (
            <LearnSongsCard
              key={song.id}
              id={song.id}
              artistName={song.artistName}
              songTitle={song.songTitle}
              subLevel={song.subLevel}
              level={showLevel ? song.level : undefined}
              thumbnailUrl={song.thumbnailUrl}
              isUserLesson={song.isUserLesson}
              hasUserVideo={song.hasUserVideo}
              hasUserBattle={song.hasUserBattle}
              action={() => childAction(song)}
            />
          ))}
          <div className="col-12">
            <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next"
                previousLabel="previous"
                onPageChange={handlePageClick}
                pageCount={pageCount} // Total number of pages
                forcePage={currentPage} // Zero-based index
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SongCardsContainer;
