import { FunctionComponent, useState, useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";
import OpponentCard from "./OpponentCard";
import { getOpponentVideosBySongId } from "../../api/opponents";
import { OpponentVideo } from "../../models/OpponentVideo";
import { challengeToBattle } from "../../api/challenges";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import ChallengeSuccessModal from "../my_challenges/ChallengeSuccessModal";
import { Helmet } from "react-helmet-async";
import MyVideoSelector from "./VideoSelector"; // Import MyVideoSelector
import { UserVideo } from "../../models/UserVideo";

const FindOpponent: FunctionComponent = () => {
    const { authState } = useAuth();
    const { tokens } = authState;

    const [selectedVideo, setSelectedVideo] = useState<UserVideo | null>(null); // Updated state type
    const [opponentVideos, setOpponentVideos] = useState<OpponentVideo[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showChallengeSuccessModal, setShowChallengeSuccessModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredOpponents, setFilteredOpponents] = useState(opponentVideos);
    const [opponentName, setOpponentName] = useState("");

    const fetchOpponents = () => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (selectedVideo) {
            try {
                const accessToken = tokens?.access_token || "";
                getOpponentVideosBySongId(accessToken, selectedVideo.song.id)
                    .then((opponentVideos: OpponentVideo[]) => {
                        setOpponentVideos(opponentVideos);
                        setFilteredOpponents(opponentVideos);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error("Fetch opponents failed:", error);
                        setError(error.message);
                        setLoading(false);
                    });
            } catch (error) {
                console.error("Fetch opponents failed:", error);
                setLoading(false);
            }
        }

        return () => {
            controller.abort();
        };
    };

    useEffect(() => {
        if (selectedVideo) {
            const cleanup = fetchOpponents();
            return cleanup;
        }
    }, [selectedVideo, tokens?.access_token]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        filterOpponents(event.target.value);
    };

    const filterOpponents = (query: string) => {
        const filtered = opponentVideos.filter(
            (opponentVideo: OpponentVideo) =>
                opponentVideo.user.firstName
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                opponentVideo.user.lastName.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredOpponents(filtered);
    };

    const handleChallengeToBattleClick = async (
        opponentVideoId: number,
        opponentName: string
    ) => {
        try {
            const accessToken = tokens?.access_token || "";
            if (selectedVideo) {
                await challengeToBattle(accessToken, Number(selectedVideo.id), opponentVideoId);
                setOpponentName(opponentName);
                setShowChallengeSuccessModal(true);
                fetchOpponents();
            }
        } catch (error) {
            console.error("Challenge to battle failed:", error);
        }
    };

    const handleCloseModal = () => {
        setShowChallengeSuccessModal(false);
    };

    const handleVideoSelect = (video: UserVideo) => {
        setSelectedVideo(video); // Update the selected video
    };

    return (
        <>
            <Helmet>
                <title>Find opponent</title>
            </Helmet>

            <div className="col-12 col-md-8">
                <div className="bg-yellow border rounded-5">
                    <div className="d-flex flex-column justify-content-between">
                        <div className="p-3">
                            <MyVideoSelector onSelectVideo={handleVideoSelect} />
                        </div>
                        {selectedVideo && (
                            <div className="d-flex gap-4 text-black p-3 border-top flex-column flex-md-row">
                                <div className="video-container-outer rounded-4" style={{ maxWidth: "200px" }}>
                                    <AWSVideoPlayerForm
                                        videoUrl={selectedVideo.videoUrl}
                                        thumbnailUrl={selectedVideo.thumbnailUrl}
                                        isPrivate={true}
                                    />
                                </div>

                                <div className="d-flex gap-4 justify-content-between align-items-center">
                                    <div>
                                        <h3 className="fs-3 m-0 fw-bold">
                                            {selectedVideo.song?.artistName || " "}
                                        </h3>
                                        <p className="mb-0 fs-6">
                                            {selectedVideo.song?.songTitle || " "}
                                        </p>
                                    </div>
                                    <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50">
                                        <small>
                                            {selectedVideo.song?.songLevel || ""} /{" "}
                                            {selectedVideo.song?.subLevel}
                                        </small>
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                </div>


            </div >



            {/* <div className="bg-green text-white">
                <div className="container-gbc pt-5">
                    <div className="row text-center pt-5">
                        <div className="col-12">
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                <h2 className="fw-normal fs-gbc-1 mb-2">
                                    Challenge players to battle
                                </h2>
                                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                                    Available players with the same song recorded.
                                </p>
                                <div className="input-group input-group-lg mw-320 custom-search mt-4">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search by username"
                                        aria-label="Search by player name"
                                        aria-describedby="basic-addon1"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                    />
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-card bg-green">
                <div className="py-5">
                    <ChallengeSuccessModal
                        show={showChallengeSuccessModal}
                        onHide={handleCloseModal}
                        opponentName={opponentName}
                    />
                    {/* {loading && (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )} */}
                    {filteredOpponents.length > 0 ? (
                        <div className="row">
                            {filteredOpponents.map((opponentVideo) => (
                                <OpponentCard
                                    key={opponentVideo.id}
                                    opponentVideo={opponentVideo}
                                    onChallengeToBattleClick={handleChallengeToBattleClick}
                                />
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-black">
                            Opponents will be shown when you select one of your recorded videos to battle.
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default FindOpponent;
