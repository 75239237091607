import { FunctionComponent, useState, useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { getUserVideos } from "../../api/videos";
import { UserVideo } from "../../models/UserVideo";
import MyVideoCard from "../my_videos/MyVideoCard";
import { Modal, Button } from "react-bootstrap"; // Importing Bootstrap modal

interface VideoSelectorProps {
    onSelectVideo: (video: UserVideo) => void; // Callback to pass selected video
}

const VideoSelector: FunctionComponent<VideoSelectorProps> = ({ onSelectVideo }) => {
    const { authState } = useAuth();
    const { tokens } = authState;

    const [videos, setVideos] = useState<UserVideo[]>([]);
    const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const fetchUserVideos = async () => {
        try {
            const accessToken = tokens?.access_token || "";
            const userVideos: UserVideo[] = await getUserVideos(accessToken);
            setVideos(userVideos);
        } catch (error) {
            console.error("Failed to fetch user videos:", error);
        }
    };

    useEffect(() => {
        fetchUserVideos();
    }, []);

    const handleSelectVideo = (video: UserVideo) => {
        setSelectedVideoId(video.id);
        onSelectVideo(video); // Pass the selected video to the parent component
        setShowModal(false); // Close the modal after selecting a video
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* Button to trigger the modal */}
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center w-100 text-black gap-3">
                <div className="d-flex flex-column">
                    <h2 className="fw-bold fs-2 mb-0">
                        Challenge players to battle
                    </h2>
                    <p className="mb-0">First select one of your recorded videos, then find opponents to challenge.</p>
                </div>
                <Button className="bttn bttn-stroke border-black d-flex" onClick={openModal}>
                    Select a video for battle
                </Button>
            </div>

            {/* Modal for selecting a video */}
            <Modal show={showModal} onHide={closeModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Your Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        {videos.length > 0 ? (
                            videos.map((video) => (
                                <div className="col-12 col-md-6 mb-4">
                                    <MyVideoCard
                                        id={video.id}
                                        video={video}
                                        isPlaying={selectedVideoId === video.id}
                                        isModalView={true}
                                        onPlay={() => { }}
                                        onFindOpponentClick={() => { }}
                                        onDelete={() => { }}
                                        onTryAgainClick={() => { }}
                                        key={video.id}
                                        onVideoSelected={() => handleSelectVideo(video)}
                                    />
                                    {/* <Button
                                        className="bttn bttn-stroke"
                                        onClick={() => handleSelectVideo(video)}
                                    >
                                        Select
                                    </Button> */}
                                </div>
                            ))
                        ) : (
                            <p>No videos available</p>
                        )}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="bttn bttn-stroke" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default VideoSelector;
