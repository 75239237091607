import React, { useState, useEffect } from "react";

interface Props {
  onTabChange: (tab: "ACTIVE" | "FINISHED" | "FIND_OPPONENTS") => void;
}

const BattleTabs: React.FC<Props> = ({ onTabChange }) => {
  const [selectedTab, setSelectedTab] =
    useState<"ACTIVE" | "FINISHED" | "FIND_OPPONENTS">("ACTIVE");

  const handleTabChange = (tab: "ACTIVE" | "FINISHED" | "FIND_OPPONENTS") => {
    setSelectedTab(tab);
    onTabChange(tab);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  return (
    <div className={scroll ? "scrolled bg-yellow border-bottom sticky-top-custom" : "not-scrolled bg-yellow border-bottom sticky-top-custom"}>
      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
        <div className="d-flex flex-row flex-md-row gap-1 justify-content-center gap-md-2 flex-wrap px-3">
          <button
            type="button"
            className={` ${selectedTab === "ACTIVE"
              ? "bttn bttn-smaller"
              : "bttn bttn-stroke bttn-smaller"
              } `}
            onClick={() => handleTabChange("ACTIVE")}
          >
            Active battles
          </button>
          <button
            type="button"
            className={` ${selectedTab === "FINISHED"
              ? "bttn bttn-smaller"
              : "bttn bttn-stroke bttn-smaller"
              } `}
            onClick={() => handleTabChange("FINISHED")}
          >
            Finished battles
          </button>
          <button
            type="button"
            className={` ${selectedTab === "FIND_OPPONENTS"
              ? "bttn bttn-smaller"
              : "bttn bttn-stroke bttn-smaller"
              } `}
            onClick={() => handleTabChange("FIND_OPPONENTS")}
          >
            Find opponents
          </button>
        </div>
      </div>
    </div>
  );
};

export default BattleTabs;
