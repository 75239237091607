import { FunctionComponent, useEffect, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SongCardsContainer from "./SongCardsContainer";
import LevelSelector from "./LevelSelector";
import { LearnSongsCardType } from "./LearnSongsCard";
import { fetchLearnSongsFromAPI } from "../../data/videosSlice";
import { useAuth } from "../../providers/AuthProvider";
import { Level } from "../../models/Level";
import { addLesson } from "../../api/lessons";
import { PayloadAction } from "@reduxjs/toolkit";
import { Helmet } from "react-helmet-async";

const LearnSongsListing: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!tokens?.access_token);
  const [loading, setLoading] = useState(true);
  const [cachedSongs, setCachedSongs] = useState<{
    [levelId: string]: LearnSongsCardType[];
  }>({});
  const [filteredSongs, setFilteredSongs] = useState<LearnSongsCardType[]>([]);
  const [totalSongs, setTotalSongs] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentLevelId = searchParams.get("level") || "beginner";
  const currentSubLevel = searchParams.get("subLevel") || "all";
  const currentPage = parseInt(searchParams.get("page") || "0", 10);

  const SONGS_PER_PAGE = 9;

  useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  // Only fetch songs when the level changes
  useEffect(() => {
    if (!cachedSongs[currentLevelId]) {
      console.log("GET SONGS FROM API");
      getSongsFromAPI(currentLevelId); // Fetch only when not cached
    } else {
      console.log("FILTER CACHED SONGS");
      updateFilteredSongs(currentLevelId, currentSubLevel, currentPage);
    }
  }, [currentLevelId, currentSubLevel, currentPage]);

  // Effect cachedSongs when it changes
  useEffect(() => {
    if (cachedSongs[currentLevelId]) {
      console.log("FILTER CACHED SONGS");
      updateFilteredSongs(currentLevelId, currentSubLevel, currentPage);
    }
  }, [cachedSongs]);

  // Function to fetch songs from the API when level changes
  const getSongsFromAPI = async (level: string) => {
    const accessToken = tokens?.access_token || "";
    setLoading(true); // Set loading state

    try {
      const action = await dispatch(
        fetchLearnSongsFromAPI({
          accessToken,
          level: level,
          subLevel: "all", // Fetch all sub-levels initially
          offset: 0,
          limit: 1000, // Fetch all songs at once for this level
        })
      );

      const fetchedSongs = action.payload.songs || [];
      console.log(`Fetched ${fetchedSongs.length} songs for level: ${level}`);

      // Cache the fetched songs
      setCachedSongs((prevCachedSongs) => {
        const updatedCache = {
          ...prevCachedSongs,
          [level]: fetchedSongs,
        };
        console.log("Cached songs for level:", level, fetchedSongs.length);
        return updatedCache;
      });

      // Update totalSongs and filteredSongs after caching
      setTotalSongs(fetchedSongs.length);
      // updateFilteredSongs(level, currentSubLevel, currentPage);
    } catch (error) {
      console.error("Error fetching songs:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Filter songs based on the sub-level and paginate them
  const updateFilteredSongs = (
    level: string,
    subLevel: string,
    page: number
  ) => {
    const songs = cachedSongs[level] || [];
    if (!songs.length) {
      console.log(`No songs found for level: ${level}`);
      setFilteredSongs([]);
      return;
    }

    let filtered = songs;
    if (subLevel !== "all") {
      filtered = songs.filter((song) => song.subLevel === subLevel);
    }

    const start = page * SONGS_PER_PAGE;
    const end = start + SONGS_PER_PAGE;

    console.log(
      `Paginating songs on page ${page} from ${start} to ${end} out of ${filtered.length}`
    );
    if (start >= filtered.length) {
      setFilteredSongs([]);
      console.warn(`Page ${page} is out of range. No songs to display.`);
      return;
    }

    // Ensure `end` does not exceed the length of the `filtered` array
    const paginatedSongs = filtered.slice(
      start,
      Math.min(end, filtered.length)
    );

    setFilteredSongs(paginatedSongs);
    setTotalSongs(filtered.length);
  };

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      if (song.hasUserBattle) {
        navigate("/my-battles");
      } else if (song.isUserLesson) {
        navigate("/learn-song-play-lesson", { state: { song } });
      } else {
        onAddToLesson(song);
      }
    },
    [navigate]
  );

  const onAddToLesson = async (song: LearnSongsCardType | undefined) => {
    if (song) {
      const accessToken = tokens?.access_token || "";
      try {
        await addLesson(accessToken, song.id);
        navigate("/learn-song-play-lesson", { state: { song } });
      } catch (error) {
        console.error("Error adding lesson:", error);
      }
    }
  };

  const handleLevelClick = (level: Level) => {
    setSearchParams({ level: level.id, subLevel: "all", page: "0" });
  };

  const handleSubLevelClick = (subLevel: string) => {
    setSearchParams({ level: currentLevelId, subLevel, page: "0" });
  };

  const handleSubLevelCloseClick = () => {
    setSearchParams({ level: currentLevelId, subLevel: "all", page: "0" });
  };

  const onPageChange = (data: { selected: number }) => {
    setSearchParams({
      level: currentLevelId,
      subLevel: currentSubLevel,
      page: data.selected.toString(),
    });
  };

  return (
    <>
      <Helmet>
        <title>{"Learn songs"}</title>
      </Helmet>

      <div className="bg-yellow pt-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Pick your difficulty level
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  You can improve your skills in the most enjoyable way
                  possible, whether you're a beginner or an intermediate guitar
                  player.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <LevelSelector
          onLevelClick={handleLevelClick}
          onSubLevelClick={handleSubLevelClick}
          onSubLevelCloseClick={handleSubLevelCloseClick}
          currentLevel={currentLevelId}
          currentSubLevel={currentSubLevel}
        />
        <div className="container-gbc py-4 py-md-5">
          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <SongCardsContainer
            childAction={onClickSong}
            songs={filteredSongs}
            pageCount={Math.max(1, Math.ceil(totalSongs / SONGS_PER_PAGE))} // Ensure pageCount is at least 1
            showLevel={false}
            onPageChange={onPageChange}
            currentPage={Math.min(
              currentPage,
              Math.max(0, Math.ceil(totalSongs / SONGS_PER_PAGE) - 1)
            )} // Ensure currentPage is within valid bounds
          />
        </div>
      </div>
    </>
  );
};

export default LearnSongsListing;
