import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import {
  acceptChallenge,
  cancelChallenge,
  getMyChallengesSent,
  getMyChallengesReceived,
} from "../../api/challenges";
import ChallengeCard from "./ChallengeCard";
import { Helmet } from "react-helmet-async";

const MyChallenges: FunctionComponent = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [challengesSent, setChallengesSent] = useState<any[]>([]);
  const [challengesReceived, setChallengesReceived] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [selectedMainTab, setSelectedMainTab] = useState("CHALLENGES");
  const [selectedTab, setSelectedTab] = useState("SENT");

  const fetchChallenges = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const sentChallenges: any[] = await getMyChallengesSent(accessToken);
      const receivedChallenges: any[] = await getMyChallengesReceived(
        accessToken
      );
      setChallengesSent(sentChallenges);
      setChallengesReceived(receivedChallenges);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(
        error instanceof Error ? error : new Error("Failed to fetch challenges")
      );
    }
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleBattlesClick = () => {
    navigate("/my-battles");
  };

  const handleChallengesClick = (tab: string) => {
    //   setActiveTab(tab);
  };

  const onCancelChallengeClick = async (battleId: number) => {
    const accessToken = tokens?.access_token || "";
    await cancelChallenge(accessToken, battleId);
    fetchChallenges();
  };

  const onAcceptChallengeClick = async (battleId: number) => {
    const accessToken = tokens?.access_token || "";
    await acceptChallenge(accessToken, battleId);
    fetchChallenges();
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"My challenges"}</title>
      </Helmet>

      <div className="bg-yellow pt-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">My challenges</h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Here you will find all your sent and received challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <div
          className={
            scroll
              ? "scrolled bg-yellow border-bottom sticky-top-custom"
              : "not-scrolled bg-yellow border-bottom sticky-top-custom"
          }
        >
          <div className="d-flex flex-column justify-content-center align-items-center gap-2">
            <div className="d-flex flex-row flex-md-row gap-2 gap-md-2 flex-wrap">
              <button
                className={` ${
                  selectedTab === "SENT"
                    ? "bttn bttn-smaller"
                    : "bttn bttn-smaller bttn-stroke"
                }`}
                onClick={() => handleTabChange("SENT")}
              >
                Sent challenges
              </button>
              <button
                className={` ${
                  selectedTab === "RECEIVED"
                    ? "bttn bttn-smaller"
                    : "bttn bttn-smaller bttn-stroke"
                }`}
                onClick={() => handleTabChange("RECEIVED")}
              >
                Received challenges
              </button>
            </div>
          </div>
        </div>

        <div className="container-gbc py-4 py-md-5 position-relative z-1">
          <div className="row">
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {selectedTab === "SENT" && challengesSent.length === 0 && (
              <div className="text-center">No sent challenges yet.</div>
            )}
            {selectedTab === "RECEIVED" && challengesReceived.length === 0 && (
              <div className="text-center">No received challenges yet.</div>
            )}
            {selectedTab === "SENT" &&
              challengesSent.map((sentChallenge) => (
                <ChallengeCard
                  key={sentChallenge.id}
                  challenge={sentChallenge}
                  type="SENT"
                  onCancelChallengeClick={onCancelChallengeClick}
                  onAcceptChallengeClick={() => {}}
                />
              ))}
            {selectedTab === "RECEIVED" &&
              challengesReceived.map((receivedChallenge) => (
                <ChallengeCard
                  key={receivedChallenge.id}
                  challenge={receivedChallenge}
                  type="RECEIVED"
                  onCancelChallengeClick={() => {}}
                  onAcceptChallengeClick={onAcceptChallengeClick}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyChallenges;
