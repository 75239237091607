import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VideoProcessor from "./VideoProcessor";
import { Video } from "../../models/Video";
import { useAuth } from "../../providers/AuthProvider";
import { getUserVideoForSong } from "../../api/songs";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import DeleteVideoConfirmationPopup from "./DeleteVideoConfirmationPopup";

const UploadVideoPage: FunctionComponent = () => {
  const location = useLocation();
  const { song } = location.state || {};
  const { authState } = useAuth();
  const { tokens } = authState;

  const [existingVideo, setExistingVideo] = useState<Video | null>(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    const fetchUserVideo = async () => {
      try {
        const accessToken = tokens?.access_token || "";
        const video: Video = await getUserVideoForSong(accessToken, song.id);

        // Update state with the fetched video
        setExistingVideo(video);
      } catch (error) {
        console.error("Failed to fetch user videos:", error);
      }
    };
    if (song?.hasUserVideo) {
      fetchUserVideo();
    }
  }, [tokens, song.id]);

  return (
    <>
      {showConfirmationPopup && existingVideo && (
        <DeleteVideoConfirmationPopup onHide={() => setShowConfirmationPopup(false)}
          video={existingVideo} token={tokens?.access_token || ""}
          onSucess={() => setExistingVideo(null)} />
      )}
      <div className="bg-yellow pt-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center py-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Upload video
                </h2>
                <h3 className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">Supported size 1GB or less.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5 align-items-center">
          <div className="row justify-content-center">
            <div className="col-12 col-md-12 col-lg-8 col-xl-7 col-xxl-6">
              <div className="card-songs h-100 rounded-5 p-4 p-md-4 d-flex flex-column justify-content-between">
                <VideoProcessor song={song} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadVideoPage;
