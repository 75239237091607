import React, { useState, ChangeEvent, FormEvent } from "react";

interface SignUpWithEmailProps {
  continue: (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => void;
}

const SignUpWithEmailForm: React.FC<SignUpWithEmailProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [tosAgreement, setTosAgreement] = useState<boolean>(false);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError("");
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tosAgreement) {
      alert("Please agree to the Terms and Conditions and Privacy Policy");
      return;
    }
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setPasswordError("");
      props.continue({ firstName, lastName, email, password });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-3 mt-md-5">
        <div className="col-6">
          <div className="mb-3 form-floating">
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              placeholder="First name"
              value={firstName}
              onChange={handleFirstNameChange}
              required
            />
            <label htmlFor="firstName" className="form-label">
              First name
            </label>
          </div>
        </div>
        <div className="col-6">
          <div className="mb-3 form-floating">
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              placeholder="Last name"
              value={lastName}
              onChange={handleLastNameChange}
              required
            />
            <label htmlFor="lastName" className="form-label">
              Last name
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="mb-3 form-floating">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Email address"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <label htmlFor="email" className="form-label">
              Email address
            </label>
          </div>
        </div>
        <div className="col-12">
          <div
            className={`mb-3 mb-md-4 form-floating ${passwordError ? "has-error" : ""}`}
          >
            <input
              type="password"
              className={`form-control ${passwordError ? "is-invalid" : ""}`}
              id="password"
              name="password"
              placeholder="********"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <label htmlFor="password" className="form-label text-truncate overflow-hidden mw-100">
              Password (min 8 characters)
            </label>
            {passwordError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {passwordError}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=" justify-content-center mb-3 mb-md-4">
        <div className="form-check w-auto">
          <input
            className="form-check-input float-none me-2"
            type="checkbox"
            id="tos-agreement"
            onChange={(e) => setTosAgreement(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="tos-agreement">
            I agree to the <a href="URL_TO_TERMS" className="text-black fw-bold">Terms and Conditions</a> and
            the <a href="URL_TO_PRIVACY_POLICY" className="text-black fw-bold">Privacy Policy</a>
          </label>
        </div>
      </div>
      <div className="mt-2">
        <button type="submit" className="bttn w-100">
          Create account
        </button>
      </div>
    </form>
  );
};

export default SignUpWithEmailForm;
